import React, { useReducer } from "react";
import "./App.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import ArtistPage from "./pages/ArtistPage";
import HomePage from "./pages/HomePage";
import { HelmetProvider } from "react-helmet-async";
import snackbarReducer from "./reducers/snackbarReducer";
import SnackbarComponent from "./components/Snackbar";
import SnackbarContext from "./contexts/SnackBarContext";
import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_ID || "", {
  track_pageview: true,
});

function App() {
  const helmetContext = {};
  const initialSnackbarState = {
    snackbar: {
      message: "",
      type: "",
      open: false,
    },
  };
  const [snackbar, dispatchSnackbar] = useReducer(
    snackbarReducer,
    initialSnackbarState
  );

  return (
    <HelmetProvider context={helmetContext}>
      <SnackbarContext.Provider value={{ snackbar, dispatchSnackbar }}>
        <SnackbarComponent></SnackbarComponent>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/artist/:slug" element={<ArtistPage />} />
          <Route path="/artists/:slug" element={<ArtistPage />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </SnackbarContext.Provider>
    </HelmetProvider>
  );
}

export default App;
