import { Button, CircularProgress } from "@mui/material";
import React, { useContext, useState } from "react";
import ArtistService from "../../services/ArtistService";
import SnackbarContext from "../../contexts/SnackBarContext";
import { WebsiteBuilderModel } from "../../models/ArtistDetails";

export default function CommunitySlide({
  artistDetails,
}: {
  artistDetails?: WebsiteBuilderModel;
}) {
  const { dispatchSnackbar } = useContext<any>(SnackbarContext);

  const [phoneNumber, setPhoneNumber] = useState<number>();
  const [email, setEmail] = useState<string>("");
  const [isSubmittingSubscriber, setIsSubmittingSubscriber] =
    useState<boolean>();

  const isEmailValid = (email: any) => {
    return new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}").test(email);
    // return new RegExp('/^[^\s@]+@[^\s@]+\.[^\s@]+$/').test(email);
  };

  const addSubscriber = () => {
    setIsSubmittingSubscriber(true);
    const data = {
      mailingListId: artistDetails?.mailingList?.mailingListId,
      artistId: artistDetails?.artistId,
      email: email,
      phoneNumber: phoneNumber,
    };
    ArtistService.addSubscriber(data)
      .then((resp: any) => {
        console.log(resp);
        setEmail("");
        setPhoneNumber(undefined);
        dispatchSnackbar({
          type: "OPEN_SNACKBAR",
          payload: {
            message: `You have been added to ${artistDetails?.artistName}'s mailing list`,
            type: "success",
          },
        });
        setIsSubmittingSubscriber(false);
      })
      .catch((err: any) => {
        console.log(err);
        setEmail("");
        setPhoneNumber(undefined);
        dispatchSnackbar({
          type: "OPEN_SNACKBAR",
          payload: {
            message: `${err.response.data.errorMessage}`,
            type: "error",
          },
        });
        setIsSubmittingSubscriber(false);
      });
  };

  const openLink = (link?: string) => {
    if (link && link.includes("http")) {
      window.open(link);
    } else {
      window.open(`https://${link}`);
    }
  };
  return (
    <div className="carousel-card">
      <h3 className="mt20">Join {artistDetails?.artistName}'s community</h3>
      <p className="mt20">{artistDetails?.community?.customText}</p>
      <div className="w60p ml-auto mr-auto mt20">
        <div className="d-flex gap20 flex-wrap jc-center mt40 mb20">
          {artistDetails?.community?.discordChannel && (
            <Button
              onClick={() => {
                openLink(artistDetails?.community?.discordChannel);
              }}
            >
              <img src="/images/logotypes/discord-logotype.svg" alt="" />
            </Button>
          )}
          {artistDetails?.community?.whatsAppGroup && (
            <Button
              onClick={() => {
                openLink(artistDetails?.community?.whatsAppGroup);
              }}
            >
              <img src="/images/logotypes/whatsapp-logo.svg" alt="" />
              <span className="ml10">WhatsApp</span>
            </Button>
          )}
          {artistDetails?.community?.instagramBroadcast && (
            <Button
              onClick={() => {
                openLink(artistDetails?.community?.instagramBroadcast);
              }}
            >
              <img src="/images/logotypes/instagram-logomark.svg" alt="" />
              <span className="ml10">Broadcast</span>
            </Button>
          )}
        </div>

        {artistDetails?.mailingList?.collectPhoneNumbers && (
          <input
            className="mt20"
            placeholder="Your phone number"
            value={phoneNumber || ""}
            type="number"
            onChange={(e: any) => {
              setPhoneNumber(e.target.value);
            }}
          />
        )}
        {artistDetails?.mailingList?.collectEmails && (
          <div>
            <input
              className="mt20"
              placeholder="Your email"
              value={email || ""}
              type="email"
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
            />
            {email !== "" && !isEmailValid(email) && (
              <p className="text-error text-left pl8">
                Enter a valid email address
              </p>
            )}
          </div>
        )}
      </div>
      {(artistDetails?.mailingList?.collectEmails ||
        artistDetails?.mailingList?.collectPhoneNumbers) && (
        <Button
          className="mt20"
          disabled={
            isSubmittingSubscriber ||
            (!email && !phoneNumber) ||
            (email !== "" && !isEmailValid(email))
          }
          onClick={() => {
            addSubscriber();
          }}
        >
          {isSubmittingSubscriber ? <CircularProgress size={16} /> : "Submit"}
        </Button>
      )}
    </div>
  );
}
