import { Button } from "@mui/material";
import React from "react";
import { WebsiteBuilderModel } from "../../models/ArtistDetails";

export default function SpotlightReleaseSlide({
  artistDetails,
}: {
  artistDetails?: WebsiteBuilderModel;
}) {
  const openLink = (link?: string) => {
    if (link && link.includes("http")) {
      window.open(link);
    } else {
      window.open(`https://${link}`);
    }
  };
  return (
    <div className="carousel-card">
      <h3 className="mt20">Stream {artistDetails?.artistName}'s music</h3>
      <p className="mt20">{artistDetails?.spotlightRelease?.customText}</p>
      <div className="d-flex gap20 flex-wrap jc-center mt62 mb20 max-w650 ml-auto mr-auto">
        {artistDetails?.spotlightRelease?.spotifyLink && (
          <Button
            onClick={() => {
              openLink(artistDetails?.spotlightRelease?.spotifyLink);
            }}
          >
            <img src="/images/logotypes/spotify-logotype.svg" alt="" />
          </Button>
        )}
        {artistDetails?.spotlightRelease?.appleMusicLink && (
          <Button
            onClick={() => {
              openLink(artistDetails?.spotlightRelease?.appleMusicLink);
            }}
          >
            <img src="/images/logotypes/apple-music-logotype.svg" alt="" />
          </Button>
        )}
        {artistDetails?.spotlightRelease?.iTunesLink && (
          <Button
            onClick={() => {
              openLink(artistDetails?.spotlightRelease?.iTunesLink);
            }}
          >
            <img src="/images/logotypes/itunes-logotype.svg" alt="" />
          </Button>
        )}
        {artistDetails?.spotlightRelease?.youTubeMusicLink && (
          <Button
            onClick={() => {
              openLink(artistDetails?.spotlightRelease?.youTubeMusicLink);
            }}
          >
            <img src="/images/logotypes/youtube-music-logotype.svg" alt="" />
          </Button>
        )}
        {artistDetails?.spotlightRelease?.amazonMusicLink && (
          <Button
            onClick={() => {
              openLink(artistDetails?.spotlightRelease?.amazonMusicLink);
            }}
          >
            <img src="/images/logotypes/amazon-music-logotype.svg" alt="" />
          </Button>
        )}
        {artistDetails?.spotlightRelease?.deezerLink && (
          <Button
            onClick={() => {
              openLink(artistDetails?.spotlightRelease?.deezerLink);
            }}
          >
            <img src="/images/logotypes/deezer-logotype.svg" alt="" />
          </Button>
        )}
      </div>
    </div>
  );
}
