import React from "react";
import { WebsiteBuilderModel } from "../../models/ArtistDetails";

export default function VideoSlide({
  artistDetails,
}: {
  artistDetails?: WebsiteBuilderModel;
}) {
  return (
    <div className="carousel-card">
      <iframe
        id="youtube-video"
        className="youtube-video-vertical-center"
        width={"100%"}
        height={315}
        src={
          artistDetails?.video?.embed ??
          artistDetails?.video?.url?.replace("watch?v=", "embed/")
        }
        title={"YouTube video player"}
        allowFullScreen
        frameBorder={0}
        allow={
          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        }
      ></iframe>
    </div>
  );
}
