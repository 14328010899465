import React, { CSSProperties, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import ArtistService from "../services/ArtistService";
import Loading from "../components/Loading";
import SEO from "../components/SEO";
import { ArtistPreviewModel } from "../models/ArtistDetails";

export default function HomePage() {
  const { slug } = useParams<string>();
  const navigate = useNavigate();
  const [artists, setArtists] = useState<ArtistPreviewModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [breakPointHit, setBreakPointHit] = useState<boolean>(false);

  const handleResize = () => {
    window.visualViewport && window.visualViewport.width <= 900
      ? setBreakPointHit(true)
      : setBreakPointHit(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [window.innerWidth]);

  const getFeaturedArtists = () => {
    ArtistService.getFeaturedArtists().then((resp: any) => {
      console.log(resp);
      setArtists(resp);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getFeaturedArtists();
  }, []);

  return (
    <div className="waves-background">
      <h1 className="w60p ml-auto mr-auto mt100">
        Discover the freshest new artists with un:hurd music
      </h1>
      {isLoading && (
        <div className="centered-loading-main h100p">
          <Loading size="small" />
        </div>
      )}
      <SEO
        title="unhurd artists"
        description="Discover the freshest new artists with un:hurd music"
        name="unhurd artists"
        image="%PUBLIC_URL%/images/logos/unhurd-logo.png"
      />
      <Swiper
        className="mt100"
        spaceBetween={30}
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={breakPointHit ? 1.3 : 4}
        centeredSlides={true}
        speed={2000}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        {artists.map((artist: any, index: number) => (
          <SwiperSlide key={index}>
            <div
              onClick={() => {
                navigate(`/artist/${artist.route}`);
              }}
              className="artist-carousel cursor-pointer"
              style={
                {
                  backgroundImage: `url(${artist?.imageUrl})`,
                } as CSSProperties
              }
            >
              <div className="artist-carousel-details">
                <h4>{artist.artistName}</h4>
                <p className="small text-faded capitalize-all">
                  {artist.genres.map(
                    (item: any, index: number) =>
                      `${item}${index < artist.genres.length - 1 ? ", " : ""}`
                  )}
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="text-faded footer">
        <p
          className="cursor-pointer"
          onClick={() => {
            window.open("https://www.unhurd.co.uk");
          }}
        >
          Are you an artist looking to promote yourself and build a community
          online?{" "}
          <span className="text-white underline cursor-pointer">
            Learn more
          </span>
        </p>
      </div>
    </div>
  );
}
