import { Button } from "@mui/material";
import React from "react";
import { WebsiteBuilderModel } from "../../models/ArtistDetails";

export default function ArtistDetailsSlide({
  artistDetails,
}: {
  artistDetails?: WebsiteBuilderModel;
}) {
  const openLink = (link: string) => {
    if (link.includes("http")) {
      window.open(link);
    } else {
      window.open(`https://${link}`);
    }
  };

  return (
    <div className="carousel-card">
      <img
        className="profile-pic mt20"
        src={artistDetails?.profileImageUrl}
        alt=""
      />
      <p className="mt20">{artistDetails?.bio}</p>
      <div className="d-flex flex-wrap jc-center gap20 mt40">
        {artistDetails?.primaryLinks?.map((item: any, index: number) => (
          <Button
            key={index}
            onClick={() => {
              openLink(item.link);
            }}
          >
            {item.customText}
          </Button>
        ))}
      </div>
    </div>
  );
}
